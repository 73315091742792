var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "curator-detail" },
    [
      _vm.loading
        ? _c("CSpinner", {
            staticStyle: { position: "absolute" },
            attrs: { color: "primary", size: "lg" },
          })
        : _vm._e(),
      !_vm.isGuest
        ? _c(
            "div",
            { staticClass: "curator-detail__controls" },
            [
              _c(
                "CButton",
                {
                  staticClass: "mr-1",
                  attrs: {
                    size: "sm",
                    color: "warning",
                    to: _vm.back || { name: "authors" },
                  },
                },
                [_c("CIcon", { attrs: { name: "cil-arrow-left" } })],
                1
              ),
              _c(
                "CButton",
                {
                  staticClass: "mr-1",
                  attrs: {
                    size: "sm",
                    color: "primary",
                    disabled: _vm.loading,
                  },
                  on: { click: _vm.store },
                },
                [_c("CIcon", { attrs: { name: "cil-save" } })],
                1
              ),
              _vm.can_delete && _vm.author?.id
                ? _c(
                    "CButton",
                    {
                      attrs: {
                        color: "danger",
                        size: "sm",
                        disabled: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          _vm.showRemoveConfirmModal = true
                        },
                      },
                    },
                    [_c("CIcon", { attrs: { name: "cil-x" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.can_delete && _vm.author?.id
        ? _c("RemoveConfirmModal", {
            attrs: {
              id: String(_vm.author?.id),
              itemName: _vm.author.id + ". " + _vm.author.fullName,
              confirmationValue: _vm.author.id + ". " + _vm.author.fullName,
              show: _vm.showRemoveConfirmModal,
              loading: _vm.loading,
            },
            on: {
              close: function ($event) {
                _vm.showRemoveConfirmModal = false
              },
              success: _vm.removeItem,
            },
          })
        : _vm._e(),
      _vm.showVideoList
        ? _c("VideoListModal", {
            attrs: { types: ["public"], show: _vm.showVideoList },
            on: {
              selcetVideo: _vm.addVideo,
              "update:show": function ($event) {
                _vm.showVideoList = $event
              },
            },
          })
        : _vm._e(),
      _c("UserAuthorModal", {
        attrs: {
          show: _vm.showUserModal,
          currentUserId: _vm.author?.user?.id || null,
        },
        on: {
          close: function ($event) {
            _vm.showUserModal = false
          },
          success: _vm.HandleUserAuthorModal,
          "update:show": function ($event) {
            _vm.showUserModal = $event
          },
        },
      }),
      _vm.author
        ? _c("div", { staticClass: "curator-detail__wrapper" }, [
            _c("div", { staticClass: "curator-detail__info" }, [
              _c("div", { staticClass: "curator-detail__picture-wrapper" }, [
                _c("div", { staticClass: "curator-detail__picture" }, [
                  _vm.author.avatarCurrent
                    ? _c("img", {
                        attrs: {
                          src: _vm.author.avatarCurrent,
                          alt: _vm.author.fullName,
                        },
                      })
                    : _c("div", { staticClass: "curator-detail__plug" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              viewBox: "0 0 35 38",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M31.8423 28.2997L22.5668 23.4655L26.1349 18.8408C26.791 18.0024 27.1804 16.9783 27.1804 15.8795V8.90569C27.1804 4.50534 22.8559 0.938477 17.5208 0.938477C12.1857 0.938477 7.86115 4.50534 7.86115 8.90569V15.8795C7.86115 16.9783 8.2505 18.0024 8.92222 18.8623L8.90662 18.8402L12.4747 23.4649L3.19926 28.2991C1.52443 29.1822 0.430664 30.7077 0.430664 32.4439V37.7531L34.6109 37.7096V32.4439C34.6109 30.7077 33.5164 29.1822 31.8661 28.3108L31.8423 28.2991V28.2997Z",
                              },
                            }),
                          ]
                        ),
                      ]),
                ]),
              ]),
              _c("div", { staticClass: "curator-detail__info-content" }, [
                _vm.author.social_networks
                  ? _c(
                      "div",
                      { staticClass: "curator-detail__networks" },
                      _vm._l(_vm.author.social_networks, function (network) {
                        return network.link
                          ? _c("a", {
                              staticClass: "curator-detail__network-item",
                              attrs: { href: network.link, target: "_blank" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.socialNetworkSvg(network.title)
                                ),
                              },
                            })
                          : _vm._e()
                      }),
                      0
                    )
                  : _vm._e(),
                _c("h4", { staticClass: "title curator-detail__name" }, [
                  _vm._v(_vm._s(_vm.author.fullName)),
                ]),
                _c("div", { staticClass: "row mt-3 mb-3" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg" },
                    [
                      _c("CInput", {
                        staticClass: "pl-0",
                        attrs: {
                          label: _vm.$t("lk.authors.store.surname"),
                          type: "text",
                          disabled: _vm.loading,
                        },
                        model: {
                          value: _vm.author.surname,
                          callback: function ($$v) {
                            _vm.$set(_vm.author, "surname", $$v)
                          },
                          expression: "author.surname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg" },
                    [
                      _c("CInput", {
                        staticClass: "pl-0",
                        attrs: {
                          label: _vm.$t("lk.authors.store.first_name"),
                          type: "text",
                          disabled: _vm.loading,
                        },
                        model: {
                          value: _vm.author.first_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.author, "first_name", $$v)
                          },
                          expression: "author.first_name",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg" },
                    [
                      _c("CInputFile", {
                        ref: "avatarInputFile",
                        attrs: {
                          label: _vm.$t("lk.authors.store.avatar"),
                          accept: ".jpg, .jpeg, .png",
                          type: "file",
                          disabled: _vm.loading,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.author.setAvatarFileFromEvent($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.can_delete
                    ? _c(
                        "div",
                        { staticClass: "col-lg" },
                        [
                          _c(
                            "CButtonGroup",
                            {
                              staticClass:
                                "form-group curator-detail__header__btns w-100",
                            },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "curator-detail__header__btn",
                                  attrs: {
                                    color: _vm.author.active
                                      ? "success"
                                      : "dark",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.author.active = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("general.isActive")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "CButton",
                                {
                                  staticClass: "curator-detail__header__btn",
                                  attrs: {
                                    color: !_vm.author.active
                                      ? "danger"
                                      : "dark",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.author.active = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("general.isNotActive")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "curator-detail__content" }, [
              _c(
                "div",
                { staticClass: "curator-detail__desc mb-4" },
                [
                  _c("h3", { staticClass: "curator-detail__subtitle title" }, [
                    _vm._v(_vm._s(_vm.$t("lk.authors.aboutTitle"))),
                  ]),
                  _c("CTextarea", {
                    attrs: { label: "Краткое описание", rows: "3" },
                    model: {
                      value: _vm.author.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.author, "description", $$v)
                      },
                      expression: "author.description",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "curator-detail__text" },
                    [
                      _c("tinymce", {
                        staticClass: "mb-0 w-100",
                        attrs: { disabled: _vm.loading, height: "500" },
                        model: {
                          value: _vm.author.about,
                          callback: function ($$v) {
                            _vm.$set(_vm.author, "about", $$v)
                          },
                          expression: "author.about",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "curator-detail__links" },
                [
                  _c(
                    "h3",
                    { staticClass: "curator-detail__subtitle title mb-4" },
                    [_vm._v(_vm._s(_vm.$t("lk.authors.social_networks")))]
                  ),
                  _vm._l(
                    _vm.author.social_networks,
                    function (linkItem, linkItemI) {
                      return _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-4" },
                          [
                            _c("CSelect", {
                              attrs: {
                                label: "",
                                value: linkItem.title,
                                options: _vm.socialNetworkOptions,
                                disabled: _vm.loading,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(linkItem, "title", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-8 d-flex align-items-start" },
                          [
                            _c("CInput", {
                              staticClass: "flex-grow-1",
                              attrs: { type: "text" },
                              model: {
                                value: linkItem.link,
                                callback: function ($$v) {
                                  _vm.$set(linkItem, "link", $$v)
                                },
                                expression: "linkItem.link",
                              },
                            }),
                            _c(
                              "CButton",
                              {
                                staticClass: "btn-sm shadow-none inline ml-3",
                                attrs: { color: "outline-danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.author.social_networks.splice(
                                      linkItemI,
                                      1
                                    )
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-x" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "success", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.author.social_networks.push({
                            title: "link",
                            link: "",
                          })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.add")) + " ")]
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "curator-detail__links" },
                [
                  _c("h3", { staticClass: "curator-detail__subtitle title" }, [
                    _vm._v(_vm._s(_vm.$t("lk.authors.links"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.author.links, function (linkItem) {
                      return linkItem.title && linkItem.link
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-lg-6 mb-2 curator-detail__links-item",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "curator-detail__links-link",
                                  attrs: {
                                    href: linkItem.link,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    staticClass: "mr-1 mb-1",
                                    attrs: { name: "cil-link", size: "sm" },
                                  }),
                                  _vm._v(" " + _vm._s(linkItem.title) + " "),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._l(_vm.author.links, function (linkItem, linkItemI) {
                    return _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm-4" },
                        [
                          _c("CInput", {
                            attrs: {
                              label: _vm.$t("lk.authors.store.linkText"),
                              type: "text",
                              disabled: _vm.loading,
                            },
                            model: {
                              value: linkItem.title,
                              callback: function ($$v) {
                                _vm.$set(linkItem, "title", $$v)
                              },
                              expression: "linkItem.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-sm-8 d-flex align-items-center" },
                        [
                          _c("CInput", {
                            staticClass: "flex-grow-1",
                            attrs: {
                              label: _vm.$t("lk.authors.store.link"),
                              type: "text",
                              disabled: _vm.loading,
                            },
                            model: {
                              value: linkItem.link,
                              callback: function ($$v) {
                                _vm.$set(linkItem, "link", $$v)
                              },
                              expression: "linkItem.link",
                            },
                          }),
                          _c(
                            "CButton",
                            {
                              staticClass: "btn-sm shadow-none inline ml-3",
                              staticStyle: { "margin-top": "12px" },
                              attrs: {
                                color: "outline-danger",
                                disabled: _vm.loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.author.links.splice(linkItemI, 1)
                                },
                              },
                            },
                            [_c("CIcon", { attrs: { name: "cil-x" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "success", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.author.links.push({ title: "", link: "" })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.add")) + " ")]
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "d-flex align-items-center mb-3" },
                [
                  _c(
                    "h3",
                    { staticClass: "curator-detail__subtitle title mr-4 mb-0" },
                    [_vm._v(_vm._s(_vm.$t("lk.authors.video")))]
                  ),
                  _c(
                    "CButton",
                    {
                      staticClass: "mr-4",
                      attrs: { disabled: _vm.loading, color: "success" },
                      on: {
                        click: function ($event) {
                          _vm.showVideoList = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.author.isVideo
                                ? "lk.videoEncrypt.replaceVideo"
                                : "lk.videoEncrypt.chooseVideo"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.author.isVideo
                    ? _c(
                        "CButton",
                        {
                          attrs: {
                            color: "danger",
                            size: "sm",
                            disabled: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.author.setVideo(null)
                            },
                          },
                        },
                        [_c("CIcon", { attrs: { name: "cil-x" } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.author.isVideo
                ? _c(
                    "div",
                    { staticClass: "curator-detail__video" },
                    [
                      _c("VideoPlayer", {
                        attrs: {
                          lessonId: "",
                          saveTime: true,
                          video: _vm.author.video,
                          controls: true,
                          types: ["public"],
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.can_delete
                ? _c("div", { staticClass: "curator-detail__user" }, [
                    _c(
                      "h3",
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lk.authors.store.user")) + " "
                        ),
                        _c(
                          "CLoadingButtonCustom",
                          {
                            attrs: { color: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.showUserModal = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.author.user
                                    ? _vm.$t("lk.authors.store.replaceUser")
                                    : _vm.$t("lk.authors.store.addUser")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.author.user
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-center",
                            staticStyle: { margin: "1rem" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.author.user.id) +
                                ". " +
                                _vm._s(_vm.author.user.name) +
                                " (" +
                                _vm._s(_vm.author.user.email) +
                                ") "
                            ),
                            _c(
                              "CButton",
                              {
                                staticClass: "btn-sm shadow-none inline ml-3",
                                attrs: { color: "outline-danger" },
                                on: {
                                  click: function ($event) {
                                    _vm.author.user = null
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-x" } })],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "CLoadingButtonCustom",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.store.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.save")) + " ")]
                  ),
                ],
                1
              ),
              _vm.author.courses && _vm.author.courses.length
                ? _c(
                    "div",
                    { staticClass: "curator-detail__courses" },
                    [
                      _c(
                        "h3",
                        { staticClass: "curator-detail__subtitle title" },
                        [_vm._v(_vm._s(_vm.$t("lk.authors.coursesTitle")))]
                      ),
                      _c("list-training", {
                        attrs: {
                          to: "training-detail",
                          courses: _vm.author.courses,
                          isCarousel: true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }