<template>
  <div class="curator-detail">
    <CSpinner v-if="loading" color="primary" size="lg" style="position: absolute" />
    <div
      v-if="!isGuest"
      class="curator-detail__controls">
      <CButton
        class="mr-1"
        size="sm"
        color="warning"
        :to="back || {name: 'authors'}"
      >
        <CIcon name="cil-arrow-left" />
      </CButton>
      <CButton
        class="mr-1"
        size="sm"
        color="primary"
        :disabled="loading"
        @click="store"
      >
        <CIcon name="cil-save" />
      </CButton>
      <CButton
        v-if="can_delete && author?.id"
        color="danger"
        size="sm"
        :disabled="loading"
        @click="showRemoveConfirmModal = true"
      >
        <CIcon name="cil-x" />
      </CButton>
    </div>

    <RemoveConfirmModal
      v-if="can_delete && author?.id"
      :id="String(author?.id)"
      :itemName="author.id + '. ' + author.fullName"
      :confirmationValue="author.id + '. ' + author.fullName"
      :show="showRemoveConfirmModal"
      :loading="loading"
      @close="showRemoveConfirmModal = false"
      @success="removeItem"
    />

    <VideoListModal
      v-if="showVideoList"
      @selcetVideo="addVideo"
      :types="['public']"
      :show.sync="showVideoList"
    />

    <UserAuthorModal
      @close="showUserModal = false"
      @success="HandleUserAuthorModal"
      :show.sync="showUserModal"
      :currentUserId="author?.user?.id || null"
    />

    <div class="curator-detail__wrapper" v-if="author">
      <div class="curator-detail__info">
        <div class="curator-detail__picture-wrapper">
          <div class="curator-detail__picture">
            <img
              v-if="author.avatarCurrent"
              :src="author.avatarCurrent"
              :alt="author.fullName"
            >
            <div class="curator-detail__plug" v-else>
              <svg viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.8423 28.2997L22.5668 23.4655L26.1349 18.8408C26.791 18.0024 27.1804 16.9783 27.1804 15.8795V8.90569C27.1804 4.50534 22.8559 0.938477 17.5208 0.938477C12.1857 0.938477 7.86115 4.50534 7.86115 8.90569V15.8795C7.86115 16.9783 8.2505 18.0024 8.92222 18.8623L8.90662 18.8402L12.4747 23.4649L3.19926 28.2991C1.52443 29.1822 0.430664 30.7077 0.430664 32.4439V37.7531L34.6109 37.7096V32.4439C34.6109 30.7077 33.5164 29.1822 31.8661 28.3108L31.8423 28.2991V28.2997Z"/>
              </svg>
            </div>
          </div>
          <!--img
            src="/img/png/flag.png"
            alt=""
            class="curator-detail__flag"
          -->
        </div>
        <div class="curator-detail__info-content">
          <div class="curator-detail__networks" v-if="author.social_networks">
            <a
              :href="network.link"
              class="curator-detail__network-item"
              target="_blank"
              v-for="network in author.social_networks"
              v-if="network.link"
              v-html="socialNetworkSvg(network.title)"
            />
          </div>
          <h4 class="title curator-detail__name">{{ author.fullName }}</h4>
          <div class="row mt-3 mb-3">
            <div class="col-lg">
              <CInput
                :label="$t('lk.authors.store.surname')"
                type="text"
                class="pl-0"
                :disabled="loading"
                v-model="author.surname"
              />
            </div>
            <div class="col-lg">
              <CInput
                :label="$t('lk.authors.store.first_name')"
                type="text"
                class="pl-0"
                :disabled="loading"
                v-model="author.first_name"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg">
              <CInputFile
                ref="avatarInputFile"
                :label="$t('lk.authors.store.avatar')"
                accept=".jpg, .jpeg, .png"
                type="file"
                :disabled="loading"
                @change="author.setAvatarFileFromEvent($event)"
              />
            </div>
            <div class="col-lg" v-if="can_delete">
              <CButtonGroup class="form-group curator-detail__header__btns w-100">
                <CButton
                  :color="author.active ? 'success' : 'dark'"
                  @click="author.active = true"
                  class="curator-detail__header__btn"
                >
                  {{ $t("general.isActive") }}
                </CButton>
                <CButton
                  :color="!author.active ? 'danger' : 'dark'"
                  @click="author.active = false"
                  class="curator-detail__header__btn"
                >
                  {{ $t("general.isNotActive") }}
                </CButton>
              </CButtonGroup>
            </div>
          </div>
        </div>
      </div>
      <div class="curator-detail__content">
        <div class="curator-detail__desc mb-4">
          <h3 class="curator-detail__subtitle title">{{ $t('lk.authors.aboutTitle') }}</h3>
          <CTextarea
            v-model="author.description"
            label="Краткое описание"
            rows="3"
          />
          <div class="curator-detail__text">
            <tinymce
              v-model="author.about"
              class="mb-0 w-100"
              :disabled="loading"
              height="500"
            />
          </div>
        </div>
        <div class="curator-detail__links">
          <h3 class="curator-detail__subtitle title mb-4">{{ $t('lk.authors.social_networks') }}</h3>
          <div class="row" v-for="(linkItem, linkItemI) in author.social_networks">
            <div class="col-sm-4">
              <CSelect
                label=""
                :value.sync="linkItem.title"
                :options="socialNetworkOptions"
                :disabled="loading"
              />
            </div>
            <div class="col-sm-8 d-flex align-items-start">
              <CInput
                type="text"
                class="flex-grow-1"
                v-model="linkItem.link"
              />
              <CButton
                class="btn-sm shadow-none inline ml-3"
                color="outline-danger"
                @click="author.social_networks.splice(linkItemI, 1)"
              >
                <CIcon name="cil-x" />
              </CButton>
            </div>
          </div>
          <CButton
            color="success"
            :disabled="loading"
            @click="author.social_networks.push({title: 'link', link: ''})"
          >
            {{ $t("general.add") }}
          </CButton>
        </div>
        <div class="curator-detail__links">
          <h3 class="curator-detail__subtitle title">{{ $t('lk.authors.links') }}</h3>
          <div class="row">
            <div
              class="col-lg-6 mb-2 curator-detail__links-item"
              v-for="linkItem in author.links"
              v-if="linkItem.title && linkItem.link"
            >
              <a
                :href="linkItem.link"
                class="curator-detail__links-link"
                target="_blank"
              >
                <CIcon name="cil-link" size="sm" class="mr-1 mb-1" />
                {{ linkItem.title }}
              </a>
            </div>
          </div>
          <div class="row" v-for="(linkItem, linkItemI) in author.links">
            <div class="col-sm-4">
              <CInput
                :label="$t('lk.authors.store.linkText')"
                type="text"
                v-model="linkItem.title"
                :disabled="loading"
              />
            </div>
            <div class="col-sm-8 d-flex align-items-center">
              <CInput
                :label="$t('lk.authors.store.link')"
                type="text"
                class="flex-grow-1"
                v-model="linkItem.link"
                :disabled="loading"
              />
              <CButton
                style="margin-top: 12px"
                class="btn-sm shadow-none inline ml-3"
                color="outline-danger"
                :disabled="loading"
                @click="author.links.splice(linkItemI, 1)"
              >
                <CIcon name="cil-x" />
              </CButton>
            </div>
          </div>
          <CButton
            color="success"
            :disabled="loading"
            @click="author.links.push({title: '', link: ''})"
          >
            {{ $t("general.add") }}
          </CButton>
        </div>
        <div class="d-flex align-items-center mb-3">
          <h3 class="curator-detail__subtitle title mr-4 mb-0">{{ $t('lk.authors.video') }}</h3>
          <CButton
            :disabled="loading"
            class="mr-4"
            color="success"
            @click="showVideoList = true"
          >
            {{
              $t(author.isVideo ? 'lk.videoEncrypt.replaceVideo' : 'lk.videoEncrypt.chooseVideo')
            }}
          </CButton>
          <CButton
            v-if="author.isVideo"
            color="danger"
            size="sm"
            :disabled="loading"
            @click="author.setVideo(null)"
          >
            <CIcon name="cil-x" />
          </CButton>
        </div>
        <div class="curator-detail__video" v-if="author.isVideo">
          <VideoPlayer
            lessonId=""
            :saveTime="true"
            :video="author.video"
            :controls="true"
            :types="['public']"
          ></VideoPlayer>
        </div>
        <div class="curator-detail__user" v-if="can_delete">
          <h3>
            {{ $t('lk.authors.store.user') }}
            <CLoadingButtonCustom
              color="primary"
              @click.native="showUserModal = true"
            >
              {{ author.user ? $t('lk.authors.store.replaceUser') : $t('lk.authors.store.addUser') }}
            </CLoadingButtonCustom>
          </h3>
          <div v-if="author.user" class="d-flex align-items-center" style="margin: 1rem">
            {{ author.user.id }}. {{ author.user.name }} ({{ author.user.email }})
            <CButton
              class="btn-sm shadow-none inline ml-3"
              color="outline-danger"
              @click="author.user = null"
            >
              <CIcon name="cil-x" />
            </CButton>
          </div>
        </div>
        <div class="text-right">
          <CLoadingButtonCustom
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click.native="store"
          >
            {{ $t('general.save') }}
          </CLoadingButtonCustom>
        </div>
        <div class="curator-detail__courses" v-if="author.courses && author.courses.length">
          <h3 class="curator-detail__subtitle title">{{ $t('lk.authors.coursesTitle') }}</h3>
          <list-training
            :to="'training-detail'"
            :courses="author.courses"
            :isCarousel="true"
          ></list-training>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VideoPlayer from '@/components/videoEncrypt/VideoPlayer'
import ListTraining from "@/components/training/ListTraining"
import RemoveConfirmModal from '@/components/common/RemoveConfirmModal.vue'
import Tinymce from '@/components/common/Tinymce.vue'
import Author from "@/assets/js/Author.class"
import VideoListModal from '@/components/training/VideoListModal.vue'
import UserAuthorModal from "@/components/training/UserAuthorModal.vue"
import { mapGetters } from "vuex";

export default {
  name: 'PageAuthorDetail',
  components: {
    VideoPlayer,
    ListTraining,
    RemoveConfirmModal,
    Tinymce,
    VideoListModal,
    UserAuthorModal,
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    back() {
      if(this.$route.params.back){
        return this.$route.params.back
      }
      if(this.$route.name === 'edit-self-author'){
        return {
          name: 'self-author'
        }
      }
      if(this.$route.name === 'edit-curator-author'){
        return {
          name: 'curators'
        }
      }
      return {
        name: 'authors'
      }
    }
  },
  data() {
    return {
      baseUrl: axios.defaults.baseURL,
      loading: false,
      author: null,
      showRemoveConfirmModal: false,
      showVideoList: false,
      showUserModal: false,
      can_edit: false,
      can_delete: false,
      socialNetworkOptions: Author.socialNetworkOptions,
      socialNetworkSvg: Author.socialNetworkSvg,
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const defaultAuthorData = {}
      if(!this.$route.params.id){
        if(this.$route.params.curator_id){
          const result = await axios.get(`${this.baseUrl}/api/v1/curators/${this.$route.params.curator_id}`).catch((e) => {
            this.$router.push({
              name: 'lk'
            })
          })
          if(result?.data?.data?.author?.id){
            this.$route.params.id = result.data.data.author.id
          }else{
            this.$toastr.success(this.$t('lk.authors.store.createNewAuthorToCurator'))
          }
          if(result?.data?.data?.user){
            defaultAuthorData['surname'] = result.data.data.user.name || ''
            defaultAuthorData['user'] = result.data.data.user
          }
        }
        if(!this.$route.params.id){
          this.author = new Author(defaultAuthorData)
          this.can_edit = false
          this.can_delete = this.$store.getters['user/getPermissions']('control_users')
          this.loading = false
          return
        }
      }

      const result = await axios.get(`${this.baseUrl}/api/v2/authors/${this.$route.params.id}`, {
        params: {
          with: ['courses', 'user']
        },
      }).catch((e) => {
        this.$router.push({
          name: 'authors'
        })
      })
      this.author = new Author(result?.data?.data || defaultAuthorData)
      this.can_edit = result?.data?.can_edit || false
      this.can_delete = result?.data?.can_delete || false
      this.loading = false
    },
    async store() {
      this.loading = true
      const isNew = !this.author.id
      const result = await axios.post(
        `${this.baseUrl}/api/v2/authors` + (this.author.id ? '/' + this.author.id : ''),
        this.author.formData(),
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params: {
            with: ['courses', 'user']
          }
        }).catch(e => {})
      this.loading = false
      if(result?.data?.data.id){
        this.$refs.avatarInputFile.$el.getElementsByTagName('input')[0].value = null
        this.$toastr.success(this.$t('general.' + (isNew ? 'created' : 'updated')))
        this.author.fill(result?.data?.data)
        this.can_edit = result?.data?.can_edit || false
        this.can_delete = result?.data?.can_delete || false
        if(isNew && !this.$route.params.curator_id){
          await this.$router.push({
            name : 'edit-author',
            params: {
              id: result.data.data.id
            }
          })
        }
        if(!this.can_delete){
          await this.$store.dispatch('user/getCurrent')
          this.$root.$emit('dropdownMenuUpdate')
        }
      }
    },
    async removeItem(id){
      if(!id){
        this.showRemoveConfirmModal = false
        this.loading = false
        return
      }
      this.loading = true
      await axios.delete('/api/v2/authors/' + id).then(res => {
        this.$toastr.success(this.$t('general.deleted'))
        this.$router.push({
          name: 'authors'
        })
      }).catch(e => {})
      this.showRemoveConfirmModal = false
      this.loading = false
    },
    addVideo(video){
      this.author?.setVideo(video)
      this.showVideoList = false
    },
    HandleUserAuthorModal(user){
      if(this.author?.user?.id !== user?.id){
        this.author.user = user
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.curator-detail {
  &__info {
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #e5e5e5;
    margin-bottom: 1.25rem;
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-start;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
    }
    .c-dark-theme & {
      border-color: rgba(255, 255, 255, 0.3);;
    }
  }
  &__header__btns {
    display: flex;
    @include media-breakpoint-up(sm) {
      display: inline-flex;
      margin-top: 27px;
    }
  }
  &__picture-wrapper {
    flex: none;
    width: 4.25rem;
    height: 4.25rem;
    position: relative;
    @include media-breakpoint-up(md) {
      width: 7rem;
      height: 7rem;
    }
    @include media-breakpoint-up(xl) {
      width: 10.625rem;
      height: 10.625rem;
    }
  }
  &__picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      object-position: center center;
      z-index: 2;
    }
  }
  &__plug {
    width: 100%;
    height: 100%;
    border: 0.0625rem solid #5A6570;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      @include media-breakpoint-up(md) {
        width: 3rem;
        height: 3rem;
      }
      @include media-breakpoint-up(xl) {
        width: 4.5rem;
        height: 4.5rem;
      }
      path {
        fill: #5A6570;
      }
    }
  }
  &__flag {
    position: absolute;
    border-radius: 0.1875rem;
    border: 0.25rem solid #FFF;
    right: 0.375rem;
    bottom: 0.375rem;
    z-index: 2;
    width: 2.5rem;
    .c-dark-theme & {
      border-color: #24252f;
    }
  }
  &__info-content {
    flex: 1 1 auto;
    width: 100%;
  }
  &__picture-wrapper + &__info-content {
    padding-top: 1.25rem;
    @include media-breakpoint-up(md) {
      padding-left: 2rem;
      padding-top: 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 3.5rem;
    }
  }
  &__networks {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.65rem;
    @include media-breakpoint-up(md) {
      gap: 0.625rem;
      margin-bottom: 0.6375rem;
    }
    @include media-breakpoint-up(xl) {
      gap: 1.125rem;
      margin-bottom: 0.9375rem;
    }
  }
  &__network-item {
    flex: none;
    border-radius: 50%;
    position: relative;
    text-decoration: none;
    border: 0.0625rem solid var(--color);
    width: 1.75rem;
    height: 1.75rem;
    @include media-breakpoint-up(md) {
      width: 2rem;
      height: 2rem;
    }
    @include media-breakpoint-up(xl) {
      width: 2.5rem;
      height: 2.5rem;
    }
    .c-dark-theme & {
      border-color: var(--table-active-row-dark-theme);
    }
    /deep/ svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 1rem;
      height: 1rem;
      @include media-breakpoint-up(xl) {
        width: 1.5rem;
        height: 1.5rem;
      }
      path, circle, polygon, g {
        fill: var(--color);
      }
    }
    /deep/ &:hover {
      path, circle, polygon, g{
        transition: 150ms ease-out;
        fill: #3686F6;
      }
    }
  }
  &__name {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    @include media-breakpoint-up(md) {
      font-size: 1.625rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 2.125rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem 0;
  }
  &__subtitle {
    margin: 0;
    font-weight: 700;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.3125rem;
      margin-bottom: 1rem;
    }
  }
  &__text {
    line-height: 150%;
    font-weight: 400;
    font-size: 0.8125rem;
    margin-bottom: -1rem;
    @include media-breakpoint-up(md) {
      font-size: 0.875rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.125rem;
    }
  }
  &__links {
    &-link{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__controls{
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 999;
  }
  .cbtn-user-back{
    position: relative;
    top: -10px;
    @include media-breakpoint-up(sm) {
      top: -20px;
    }
  }
}
</style>
